import { gql } from "@apollo/client";

export const CREATE_SERVICE_ACCOUNT = gql`
  mutation CreateServiceAccount($input: CreateServiceAccountInput) {
    createServiceAccount(input: $input) {
      mrn
      certificate
      privateKey
      scopeMrn
      apiEndpoint
      spaceMrn
    }
  }
`;
