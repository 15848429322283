import { gql } from "@apollo/client";

export const EDIT_PROPERTIES = gql`
  mutation EditProperties($input: PropertyQueryMutationInput!) {
    applyPropertyQueryMutation(input: $input) {
      ... on PropertyQueryMutationInvalidTarget {
        message
        code
      }
      ... on PropertyQueryMutationInvalidProperty {
        message
        code
      }
      ... on PropertyQueryMutationSuccess {
        success
      }
    }
  }
`;
