import { gql } from "@apollo/client";

export const SET_ASSET_ANNOTATIONS = gql`
  mutation SetAssetAnnotations($input: SetAssetAnnotationsInput!) {
    setAssetAnnotations(input: $input) {
      annotations {
        key
        value
      }
    }
  }
`;
