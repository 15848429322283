import { gql } from "@apollo/client";

export const CHANGE_SUBSCRIPTION_PLAN = gql`
  mutation ChangeSubscriptionPlan($input: ChangeSubscriptionPlanInput!) {
    changeSubscriptionPlan(input: $input) {
      __typename
      ... on BillingSession {
        url
      }
      ... on PaymentPreview {
        currency
        recurringCost
        dueToday
        prorationDate
        effectiveDate
      }
    }
  }
`;
