import { gql } from "@apollo/client";

export const MODIFY_SPACE_RISK_FACTORS = gql`
  mutation ModifySpaceRiskFactors(
    $spaceMrn: String!
    $riskFactors: [ModifyRiskFactorInput!]!
  ) {
    modifyRiskFactors(spaceMrn: $spaceMrn, riskFactors: $riskFactors)
  }
`;
