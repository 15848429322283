import { gql } from "@apollo/client";

export const LOAD_CVE_SPACE = gql`
  query LoadCveSpace($id: ID!, $spaceMrn: String!) {
    cve(id: $id, spaceMrn: $spaceMrn) {
      cvssScore {
        id
        source
        type
        value
        vector
      }
      epssScore {
        percentile
        probability
      }
      cwe
      description
      id
      modifiedAt
      mrn
      publishedAt
      source {
        id
        name
        url
      }
      url
      references
      state
      summary
      title
      vulnerabilityRiskFactors {
        ...VulnerabilityRiskFactorsFields
      }
      exploits {
        description
        id
        modified
        mrn
        published
        title
      }
    }
  }
`;
