import { gql } from "@apollo/client";

export const AGENT_FIELDS = gql`
  fragment AgentFields on AgentsConnection {
    totalCount
    edges {
      cursor
      node {
        id
        mrn
        name
        status {
          version
          state
          lastCheckin
        }
        labels {
          key
          value
        }
        platform {
          name
          release
          arch
        }
        hostname
        ipaddress
        createdAt
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
  }
`;
