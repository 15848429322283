import { gql } from "@apollo/client";

export const REQUEST_DOCUMENT_DOWNLOAD_URL = gql`
  mutation RequestDocumentDownloadUrl($documentMrn: String!) {
    requestDocumentDownloadUrl(documentMRN: $documentMrn) {
      downloadUrl
      expiresAt
    }
  }
`;
