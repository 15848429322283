import { gql } from "@apollo/client";

export const GET_ACTIVE_COMPLIANCE_FRAMEWORKS = gql`
  query GetActiveComplianceFrameworks($input: ComplianceFrameworksInput!) {
    complianceFrameworks(input: $input) {
      mrn
      name
      version
      authors {
        name
        email
      }
      tags {
        key
        value
      }
      description
      summary
      stats {
        controls {
          activeControls
          snoozedControls
          disabledControls
          outOfScopeControls
          totalControls
          averageCompletion
          previousAverageCompletion
        }
        checks
        assets
        exceptions
      }
      state
      policiesMrns
      scopeMrn
      completion
      previousCompletionScores {
        entries {
          timestamp
          score
        }
      }
      assetGroupStatistics {
        listsAssetTypes {
          assetType
          aliases
          displayName
          shortName
        }
        groupType
        count
        statistics {
          type {
            assetType
            aliases
            displayName
            shortName
          }
          count
        }
      }
      createdAt
      modifiedAt
    }
  }
`;
