import { gql } from "@apollo/client";

export const ADVISORY_NODE_FIELDS = gql`
  fragment AdvisoryNodeFields on Advisory {
    id
    title
    publishedAt
    cvssScore {
      id
      source
      type
      value
      vector
    }
    vulnerabilityRiskFactors {
      ...VulnerabilityRiskFactorsFields
    }
    cves {
      id
      vulnerabilityRiskFactors {
        ...VulnerabilityRiskFactorsFields
      }
    }
  }
`;
