import { gql } from "@apollo/client";

export const LOAD_TOP_POLICY_ACTIONS = gql`
  query LoadTopPolicyActions($input: TopPolicyActionsInput!) {
    topPolicyActions(input: $input) {
      name
      severity
      score {
        total
      }
      codeId
    }
  }
`;
