import { gql } from "@apollo/client";

export const ASSET_FIELDS = gql`
  fragment AssetFields on AssetsConnection {
    totalCount
    edges {
      cursor
      node {
        id
        mrn
        name
        state
        score {
          type
          value
          completion
        }
        updatedAt
        labels {
          key
          value
        }
        platform {
          name
          title
          release
          arch
          kind
          runtime
        }
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
  }
`;
