import { gql } from "@apollo/client";
import { ADVISORY_NODE_FIELDS } from "./AdvisoryNodeFields";

export const ADVISORY_FIELDS = gql`
  ${ADVISORY_NODE_FIELDS}
  fragment AdvisoryFields on AdvisoriesConnection {
    totalCount
    edges {
      cursor
      node {
        ...AdvisoryNodeFields
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
  }
`;
