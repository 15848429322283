import { gql } from "@apollo/client";

export const LOAD_TOP_VULNERABILITY = gql`
  query LoadTopVulnerability($input: TopVulnerabilityInput!) {
    topVulnerability(input: $input) {
      id
      title
      score
      published
      assetsAffectedCount
    }
  }
`;
