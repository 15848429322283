import { gql } from "@apollo/client";

export const GET_RISK_FACTORS = gql`
  query GetRiskFactors($spaceMrn: String!, $mrns: [String!]) {
    riskFactors(spaceMrn: $spaceMrn, mrns: $mrns) {
      edges {
        mrn
        title
        docs {
          active
        }
      }
    }
  }
`;
