import { gql } from "@apollo/client";

export const AUDIT_LOG_FORWARD_PAGINATION = gql`
  query AuditLogForwardPagination(
    $first: Int
    $after: String
    $orderBy: AuditLogOrder = { direction: DESC, field: TIMESTAMP }
    $resourceMrn: String!
  ) {
    auditlog(
      first: $first
      after: $after
      orderBy: $orderBy
      resourceMrn: $resourceMrn
    ) {
      totalCount
      edges {
        cursor
        node {
          identity {
            name
            mrn
          }
          resource
          action
          timestamp
          msg
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`;
