import { gql } from "@apollo/client";

export const UPDATE_USER_NAME = gql`
  mutation UpdateUserName($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        name
        mrn
      }
    }
  }
`;
