import { gql } from "@apollo/client";

export const APPLY_SPACE_PLAN = gql`
  mutation ApplySpacePlan($input: ApplySpacePlanInput!) {
    applySpacePlan(input: $input) {
      ... on ApplySpacePlanSuccess {
        success
      }
      ... on ApplySpacePlanError {
        code
        message
      }
    }
  }
`;
