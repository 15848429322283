import { gql } from "@apollo/client";

export const DELETE_ASSETS = gql`
  mutation DeleteAssets($input: DeleteAssetsInput!) {
    deleteAssets(input: $input) {
      assetMrns
      errors
    }
  }
`;
