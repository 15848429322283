import { gql } from "@apollo/client";

export const DELETE_AGENTS = gql`
  mutation DeleteAgents($input: DeleteAgentsInput!) {
    deleteAgents(input: $input) {
      agentMrns
      errors
    }
  }
`;
