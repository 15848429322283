import { gql } from "@apollo/client";
import { CVE_FIELDS } from "../fragments/CveFields";

export const SPACE_CVE_FORWARD_PAGINATION = gql`
  query SpaceCveForwardPagination(
    $after: String
    $first: Int
    $query: String
    $spaceMrn: String!
  ) {
    space(mrn: $spaceMrn) {
      id
      mrn
      cves(
        after: $after
        first: $first
        orderBy: { direction: DESC, field: SCORE }
        query: $query
      ) {
        ...CveFields
      }
    }
  }
  ${CVE_FIELDS}
`;
