import { gql } from "@apollo/client";

export const LOAD_CVE = gql`
  query LoadCve($cveId: ID!) {
    cve(id: $cveId) {
      title
      summary
      state
      source {
        id
        name
        url
      }
      url
      references
      publishedAt
      modifiedAt
      mrn
      id
      description
      cvssScore {
        vector
        id
        value
        type
        source
      }
      epssScore {
        percentile
        probability
      }
      cwe
      vulnerabilityRiskFactors {
        ...VulnerabilityRiskFactorsFields
      }
      exploits {
        description
        id
        modified
        mrn
        published
        title
      }
    }
  }
`;
