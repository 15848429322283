import { gql } from "@apollo/client";
import { CVE_NODE_FIELDS } from "./CveNodeFields";

export const CVE_FIELDS = gql`
  ${CVE_NODE_FIELDS}
  fragment CveFields on CvesConnection {
    totalCount
    edges {
      cursor
      node {
        ...CveNodeFields
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
  }
`;
