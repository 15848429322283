import { gql } from "@apollo/client";

export const REVOKE_REGISTRATION_TOKEN = gql`
  mutation RevokeRegistrationToken($input: RevokeRegistrationTokenInput!) {
    revokeRegistrationToken(input: $input) {
      ... on RevokeRegistrationTokenSuccess {
        ok
      }
      ... on RevokeRegistrationTokenFailure {
        message
        code
      }
    }
  }
`;
