import { gql } from "@apollo/client";

export const LOAD_SPACE_PLAN = gql`
  query LoadSpacePlan($input: SpacePlanInput!) {
    spacePlan(input: $input) {
      __typename
      ... on SpacePlanInfo {
        planApplied
        scoringSummary {
          total
          pass
          fail
          error
        }
        active {
          queries {
            mquery {
              uid
              mrn
              title
              impact {
                rating
                value
              }
              action
            }
            policies
            scoreDistribution {
              pass
              fail
              error
              unknown
            }
          }
          title
        }
        milestones {
          uid
          queries {
            mquery {
              uid
              mrn
              title
              impact {
                rating
                value
              }
              action
            }
            policies
            scoreDistribution {
              pass
              fail
              error
              unknown
            }
          }
          title
          justification
          endDate
        }
        exceptions {
          queries {
            mquery {
              uid
              mrn
              title
              impact {
                rating
                value
              }
              action
            }
            policies
            scoreDistribution {
              pass
              fail
              error
              unknown
            }
          }
          title
        }
      }
    }
  }
`;
