import { gql } from "@apollo/client";

export const CREATE_SPACE = gql`
  mutation CreateSpace($input: CreateSpaceInput!) {
    createSpace(input: $input) {
      id
      mrn
    }
  }
`;
