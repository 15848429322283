import { gql } from "@apollo/client";

export const MQUERY_DOCS_FIELDS = gql`
  fragment MqueryDocsFields on MqueryDocs {
    audit
    desc
    references {
      title
      url
    }
    remediations {
      ... on Remediations {
        entries {
          id
          desc
        }
      }
      ... on AuthenticationRequired {
        message
        code
      }
    }
  }
`;
