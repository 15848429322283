import { gql } from "@apollo/client";

export const UPDATE_API_TOKEN = gql`
  mutation UpdateAPIToken($input: UpdateAPITokenInput!) {
    updateAPIToken(input: $input) {
      mrn
      spaceMrn
      name
      description
      roles {
        mrn
        title
      }
      createdAt
      lastUsed
      creator {
        mrn
        email
      }
    }
  }
`;
