import { gql } from "@apollo/client";

export const LOAD_WORST_POLICIES = gql`
  query LoadWorstPolicies($input: RatedPoliciesInput!) {
    worstPolicies(input: $input) {
      mrn
      name
      score
    }
  }
`;
