import { gql } from "@apollo/client";

export const SET_ROLES = gql`
  mutation SetRoles($input: SetRolesInput!) {
    setRoles(input: $input) {
      mrns
      errors
    }
  }
`;
