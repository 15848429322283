import { gql } from "@apollo/client";

export const REMEDIATION_SCRIPT_FIELDS = gql`
  fragment RemediationScriptFields on RemediationScript {
    type
    script
    packageManager
    platformName
    platformVersion
  }
`;
