import { gql } from "@apollo/client";
import { ADVISORY_FIELDS } from "../fragments/AdvisoryFields";

export const SPACE_ADVISORY_FORWARD_PAGINATION = gql`
  query SpaceAdvisoryForwardPagination(
    $spaceMrn: String!
    $after: String
    $first: Int
    $query: String
  ) {
    space(mrn: $spaceMrn) {
      id
      advisories(
        after: $after
        first: $first
        orderBy: { direction: DESC, field: SCORE }
        query: $query
      ) {
        ...AdvisoryFields
      }
    }
  }
  ${ADVISORY_FIELDS}
`;
