import { gql } from "@apollo/client";

export const SUPPRESS_INTEGRATION_MESSAGE = gql`
  mutation SuppressIntegrationMessage(
    $input: SuppressIntegrationMessageInput!
  ) {
    suppressIntegrationMessage(input: $input) {
      mrn
    }
  }
`;
