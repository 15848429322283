import { gql } from "@apollo/client";

export const RISK_DISTRIBUTION = gql`
  fragment RiskDistribution on SpaceStatistics {
    riskdistribution {
      total
      U
      A
      B
      C
      D
      F
      Error
    }
  }
`;
