import { gql } from "@apollo/client";
import { ADVISORY_FIELDS } from "../fragments/AdvisoryFields";

export const ADVISORY_FORWARD_PAGINATION = gql`
  query AdvisoryForwardPagination($after: String, $first: Int, $query: String) {
    advisories(
      after: $after
      first: $first
      orderBy: { direction: DESC, field: PUBLISHED }
      query: $query
    ) {
      ...AdvisoryFields
    }
  }
  ${ADVISORY_FIELDS}
`;
