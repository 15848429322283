import { gql } from "@apollo/client";

export const GET_AGGREGATE_SCORES = gql`
  query GetAggregateScores(
    $entityMrn: String!
    $filter: AggregateScoreFilter
    $first: Int
    $after: String
    $orderBy: AggregateScoreOrder
  ) {
    aggregateScores(
      entityMrn: $entityMrn
      filter: $filter
      first: $first
      after: $after
      orderBy: $orderBy
    ) {
      ... on AggregateScoresConnection {
        totalCount
        edges {
          cursor
          node {
            id
            iconId
            state
            entity {
              ... on EntityInfoAsset {
                id
                mrn
                name
              }
              ... on EntityInfoSpace {
                id
                mrn
                name
              }
            }
            findingMrn
            rank
            baseScore
            riskScore
            scoreType
            blastRadius {
              ...BlastRadiusFields
            }
            epss {
              probability
              percentile
            }
            cvss {
              id
              value
              type
              vector
              source
            }
            riskFactors {
              mrn
              indicator
              title
              total
              affected
            }
            title
            description
            tags {
              key
              value
            }
            lastScannedAt
            firstDetectedAt
            remediatedAt
            spaceId
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
      ... on RequestError {
        message
        code
      }
      ... on NotFoundError {
        message
        code
      }
    }
  }
`;
