import { gql } from "@apollo/client";

export const CVE_NODE_FIELDS = gql`
  fragment CveNodeFields on Cve {
    id
    state
    published
    title
    vulnerabilityRiskFactors {
      ...VulnerabilityRiskFactorsFields
    }
    epssScore {
      probability
    }
    cvssScore {
      type
      value
      vector
    }
    score {
      value
      type
    }
  }
`;
