import { gql } from "@apollo/client";

export const REMEDIATION_PACKAGE_FIELDS = gql`
  fragment RemediationPackageFields on Package {
    id
    name
    version
    namespace
    arch
  }
`;
