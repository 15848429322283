import { gql } from "@apollo/client";

export const GET_POLICY_PROPS = gql`
  query GetPolicyProps($input: PolicyInput!, $scopeInput: PolicyInput!) {
    policy(input: $input) {
      uid
      mrn
      properties {
        uid
        mrn
        mql
        title
      }
      queries {
        uid
        mrn
        properties {
          uid
          mrn
          mql
          title
        }
      }
    }
    scopePolicy: policy(input: $scopeInput) {
      uid
      mrn
      properties {
        uid
        mrn
        mql
        title
      }
    }
  }
`;
