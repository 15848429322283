import { gql } from "@apollo/client";

export const PACKAGE_FIELDS = gql`
  fragment PackageFields on Package {
    id
    name
    version
    arch
    available
    status
    vulnerabilityRiskFactors {
      ...VulnerabilityRiskFactorsFields
    }
    vulnDiscoveryTime
    iconIDEnum
    cvssScore {
      type
      value
      vector
    }
    epssScore {
      percentile
      probability
    }
  }
`;
