import { gql } from "@apollo/client";

export const GENERATE_REGISTRATION_TOKEN = gql`
  mutation GenerateRegistrationToken($input: RegistrationTokenInput!) {
    generateRegistrationToken(input: $input) {
      id
      mrn
      description
      labels {
        key
        value
      }
      token
      revoked
      createdAt
      createdBy
      expiresAt
    }
  }
`;
