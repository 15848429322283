import { gql } from "@apollo/client";

export const GET_MONDOO_VULNERABILITY_DATABASE = gql`
  query GetMondooVulnerabilityDatabase(
    $first: Int
    $after: String
    $orderBy: MvdOrder
    $queryTerms: [String!]
    $type: MvdEntryType!
    $severity: [Severity!]
  ) {
    mvd(
      first: $first
      after: $after
      orderBy: $orderBy
      queryTerms: $queryTerms
      type: $type
      severity: $severity
    ) {
      edges {
        cursor
        node {
          ... on Advisory {
            id
            iconIDEnum
            mrn
            title
            cvssScore {
              id
              value
              type
              vector
              source
            }
            publishedAt
          }
          ... on Cve {
            id
            mrn
            title
            publishedAt
            cvssScore {
              id
              source
              type
              value
              vector
            }
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;
