import { gql } from "@apollo/client";

export const SERVICE_ACCOUNT_FIELDS = gql`
  fragment ServiceAccountFields on ServiceAccountConnection {
    totalCount
    edges {
      cursor
      node {
        id
        mrn
        name
        description
        roles {
          mrn
          title
        }
        createdAt
        lastUsed
        labels {
          key
          value
        }
        creator {
          mrn
          email
          service
        }
        notes
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
  }
`;
