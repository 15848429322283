import { gql } from "@apollo/client";

export const GENERATE_API_TOKEN = gql`
  mutation GenerateAPIToken($input: GenerateAPITokenInput!) {
    generateAPIToken(input: $input) {
      token
      spaceMrn
      scopeMrn
      name
      mrn
      description
      createdBy
      createdAt
    }
  }
`;
