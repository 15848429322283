import { gql } from "@apollo/client";

export const LOAD_ADVISORY = gql`
  query LoadAdvisory($id: ID!, $spaceMrn: String) {
    advisory(id: $id, spaceMrn: $spaceMrn) {
      vendorscore
      title
      vulnerabilityRiskFactors {
        ...VulnerabilityRiskFactorsFields
      }
      externalUrls {
        iconIDEnum
        url
        title
      }
      publishedAt
      mrn
      modifiedAt
      id
      assets {
        id
        mrn
        name
        platformName
        platformVersion
        advisoryInfo {
          activeAdvisoryCount
          worstCvssScore {
            id
            source
            type
            value
            vector
          }
        }
        score {
          type
          value
        }
      }
      fixedByPackages {
        id
        name
        version
        arch
        format
        namespace
        description
        status
        available
        origin
        score {
          id
          value
          type
          vector
          source
        }
      }
      description
      cvssScore {
        id
        value
        type
        vector
        source
      }
    }
  }
`;
