import { gql } from "@apollo/client";

export const UPDATE_SPACE = gql`
  mutation UpdateSpace($input: UpdateSpaceInput!) {
    updateSpace(input: $input) {
      space {
        name
        mrn
        settings {
          eolAssetsConfiguration {
            enable
            monthsInAdvance
          }
          garbageCollectAssetsConfiguration {
            enable
            afterDays
          }
          terminatedAssetsConfiguration {
            cleanup
            after
          }
          unusedServiceAccountsConfiguration {
            cleanup
            after
          }
          platformVulnerabilityConfiguration {
            enable
          }
          casesConfiguration {
            autoCreate
            aggregationWindow
          }
        }
      }
    }
  }
`;
