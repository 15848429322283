import { gql } from "@apollo/client";

export const LOAD_ADVISORY_CVES = gql`
  query LoadAdvisoryCves(
    $advisoryId: ID!
    $assetMrn: String
    $spaceMrn: String
  ) {
    advisory(id: $advisoryId, assetMrn: $assetMrn, spaceMrn: $spaceMrn) {
      cves {
        id
        mrn
        riskScore
        source {
          id
          name
          url
        }
        title
        description
        summary
        publishedAt
        modifiedAt
        url
        cvssScore {
          id
          value
          type
          vector
          source
        }
        cwe
        state
        assets {
          id
          mrn
          name
          score {
            id
            value
            type
            completion
            weight
            message
            grade
          }
          platformName
          platformVersion
          advisoryInfo {
            activeAdvisoryCount
            worstCvssScore {
              id
              value
              type
              vector
              source
            }
          }
        }
        vulnerabilityRiskFactors {
          ...VulnerabilityRiskFactorsFields
        }
      }
    }
  }
`;
