import { gql } from "@apollo/client";

export const LOAD_BEST_POLICIES = gql`
  query LoadBestPolicies($input: RatedPoliciesInput!) {
    bestPolicies(input: $input) {
      mrn
      name
      score
    }
  }
`;
