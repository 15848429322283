import { gql } from "@apollo/client";

export const GET_AVAILABLE_COMPLIANCE_FRAMEWORKS = gql`
  query GetAvailableComplianceFrameworks($input: ComplianceFrameworksInput!) {
    complianceFrameworks(input: $input) {
      description
      mrn
      scopeMrn
      name
      summary
      state
      authors {
        name
        email
      }
      tags {
        key
        value
      }
    }
  }
`;
