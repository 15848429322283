import { gql } from "@apollo/client";

export const DELETE_SERVICE_ACCOUNTS = gql`
  mutation DeleteServiceAccounts($input: DeleteServiceAccountsInput!) {
    deleteServiceAccounts(input: $input) {
      mrns
      errors
    }
  }
`;
