import { gql } from "@apollo/client";

export const GET_METRICS = gql`
  query GetMetrics($entityMrn: String!, $metricMrns: [String!]) {
    metrics(entityMrn: $entityMrn, metricMrns: $metricMrns) {
      ... on MetricsResponse {
        entries {
          metricMrn
          title
          arrowResult
        }
      }
    }
  }
`;
